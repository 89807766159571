import React from 'react';
import PropTypes from 'prop-types';

// Material DEsign
import { TextField } from '@rmwc/textfield';

// Componentes personalizados
import ErrorMessage from './error-message';

// Componentes personalizados
const TextInput = ({
  invalid,
  value,
  required,
  onChange,
  onBlur,
  type,
  outlined,
  name,
  error,
  label,
}) => {
  return (
    <div>
      <TextField
        invalid={invalid}
        style={{ width: '100%' }}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        outlined={outlined}
        name={name}
        label={label}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
};

TextInput.propTypes = {
  invalid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  outlined: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
};

export default TextInput;
