/* eslint-disable */
import React, { useState } from 'react';
import { Formik } from 'formik';
import axios from 'axios';
import is from 'is_js';
import shortid from 'shortid';

// Material Design
import { Select } from '@rmwc/select';
import { Typography } from '@rmwc/typography';
import { SimpleDialog } from '@rmwc/dialog';
import { Snackbar } from '@rmwc/snackbar';
import { Row, Cell } from '@material/react-layout-grid';
import Button from '@material/react-button';

// Teléfono
import PhoneInput from 'react-phone-input-2';

// Estilos extras
import '@rmwc/radio/styles';
import '@rmwc/select/styles';
import '@rmwc/snackbar/styles';
import '@rmwc/dialog/styles';
import 'react-phone-input-2/lib/material.css';

// Componentes internos
import InputFile from '../shared/input-file';
import TextInput from '../shared/text-input';
import ErrorMessage from '../shared/error-message';

// Componentes estilizados
import Radio from '../../styled-components/radio';

// Schema para Validacioń
import Seminario2020Schema from './seminario-2020-schema';

const Seminario2020Form = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    message: '',
  });

  const [languageLevels] = useState([
    'Ninguno',
    'La escribe',
    'La entiende',
    'La habla',
  ]);
  const [grades] = useState([
    '1er (Primero)',
    '2o (Segundo)',
    '3er (Tercero)',
    '4o (Cuarto)',
    '5o (Quinto)',
    '6o (Sexto)',
    '7o (Séptimo)',
    '8o (Octavo)',
    '9o (Noveno)',
    '10o (Décimo)',
    '11o (Onceavo)',
    '12o (Doceavo)',
  ]);

  const submitData = async (values, { setSubmitting, resetForm }) => {
    const folio = shortid.generate();
    // Creación de form data para subir archivos a Dropbox
    const formData = new FormData();

    formData.append(
      'documentos',
      values.constancia,
      `Constancia de estudios - ${values.firstName} ${values.lastName}.pdf`,
    );
    formData.append(
      'documentos',
      values.cartaPost,
      `Constancia de postulación - ${values.firstName} ${values.lastName}.pdf`,
    );
    formData.append(
      'documentos',
      values.ineFile,
      `INE - ${values.firstName} ${values.lastName}.pdf`,
    );

    formData.append(
      'documentos',
      values.compMed,
      `Comprobante de seguro médico - ${values.firstName} ${values.lastName}.pdf`,
    );

    formData.append(
      'documentos',
      values.cartaOrigen,
      `Carta de origen y vecindad - ${values.firstName} ${values.lastName}.pdf`,
    );

    try {
      setSnack({ message: 'Subiendo documentos', open: true });

      // Subimos a Google Drive
      await axios({
        method: 'POST',
        url: 'https://rocky-brushlands-23814.herokuapp.com/api/v1/drive/files',
        data: formData,
        headers: {
          'x-api-key': process.env.GATSBY_CMUJER_API_KEY,
          'Content-Type': 'multipart/form-data',
        },
        params: {
          folderId: '1yfORtlKYwmINh1XuEgPB2W1l44JMW1DW',
          folderName: `${values.firstName} ${values.lastName}`,
          folio,
        },
      });

      // Una vez creados los documentos en Dropbox, guardo y genero
      // el QR en la misma carpeta, por eso tengo que esperar
      // que se termine de hacer la petición
      setSnack({
        message: 'Los documentos se han subido correctamente.',
        open: true,
      });

      try {
        const birthArr = values.birthday.split('-');
        const birthday = new Date(birthArr[0], birthArr[1] + 1, birthArr[2]);

        await axios({
          method: 'POST',
          url:
            'https://rocky-brushlands-23814.herokuapp.com/api/v1/lists/5ebb7a138e/members',
          headers: {
            'x-api-key': process.env.GATSBY_CMUJER_API_KEY,
            'Content-Type': 'application/json',
          },
          data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            tag: 'event-ctl-2020',
            vip: true,
            mergeFields: {
              SM2020_F: folio,
              PHONE: values.phoneNumber,
              ADDRESS: {
                addr1: values.street,
                addr2: values.location,
                city: values.district,
                state: values.state,
                country: values.country,
                zip: values.zipCode,
              },
              GENRE: values.genre,
              BIRTHCITY: values.birthcity,
              BIRTHDAY_F: `${birthday.getDate()}/${birthday.getMonth() +
                1}/${birthday.getFullYear()}`,
              I_POPUL: values.population,
              I_LANG: values.language,
              I_LANGL: values.languageLevel,
              U_NAME: values.institutionName,
              U_PHONE: values.institutionPhoneNumber,
              U_CARRER: values.institutionCarrer,
              U_GRADE: values.institutionGrade,
              Y_URL: values.youtubeUrl,
            },
          },
        });

        // Indicamos que ya no se está subiendo nada
        setSubmitting(false);

        // Abrimos un dialogo con el mensaje de confirmación de registro
        setTimeout(() => {
          setDialogOpen(true);
        }, 3000);

        // Solamente si se ejecutó todo con éxito, limpiamos el formulario
        resetForm();
      } catch (error) {
        console.log(error);
        setSnack({
          open: true,
          message:
            'Hubo un error al procesar la solicitud, inténtalo nuevamente.',
        });
      }
    } catch (error) {
      console.log(error);
      setSnack({
        open: true,
        message: 'Hubo un error al subir los documentos, inténtalo nuevamente.',
      });
    }
  };

  return (
    <>
      <SimpleDialog
        title="Tu registro se ha completado"
        body="Muchas gracias por tu registro, pronto te enviaremos un correo de confirmación."
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <Snackbar
        open={snack.open}
        onClose={() =>
          setSnack({
            open: false,
            message: '',
          })
        }
        message={snack.message}
      />
      <Formik
        initialValues={{
          email: '',
          phoneNumber: '',
          country: 'MX',
          firstName: '',
          lastName: '',
          genre: 'female',
          birthday: '',
          birthcity: '',
          street: '',
          location: '',
          district: '',
          state: '',
          zipCode: '',
          population: '',
          language: '',
          languageLevel: '',
          institutionName: '',
          institutionPhoneNumber: '',
          institutionCarrer: '',
          institutionGrade: '',
        }}
        validationSchema={Seminario2020Schema}
        onSubmit={submitData}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          dirty,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Cell desktopColumns={12} tabletColumns={8}>
                <TextInput
                  invalid={errors.firstName && touched.firstName && dirty}
                  value={values.firstName}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  outlined
                  name="firstName"
                  label="* Nombre(s)"
                  error={errors.firstName}
                />
              </Cell>
              <Cell desktopColumns={12} tabletColumns={8}>
                <TextInput
                  invalid={errors.lastName && touched.lastName && dirty}
                  value={values.lastName}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  outlined
                  name="lastName"
                  label="* Apellido(s)"
                  error={errors.lastName}
                />
              </Cell>

              <Cell desktopColumns={12} tabletColumns={8}>
                <Typography
                  className="mdc-color__secondary"
                  use="body1"
                  tag="div"
                >
                  * Sexo
                </Typography>

                {[
                  ['Femenino', 'female'],
                  ['Masculino', 'male'],
                ].map((item) => (
                  <Radio
                    label={item[0]}
                    value={item[1]}
                    name="genre"
                    checked={values.genre === item[1]}
                    onChange={(event) => {
                      setFieldValue('genre', event.currentTarget.value);
                    }}
                    color="#3d2562"
                  />
                ))}
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  outlined
                  value={values.birthday}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  type="date"
                  name="birthday"
                  label="* Fecha de nacimiento"
                  invalid={errors.birthday && touched.birthday}
                  error={errors.birthday}
                />
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.birthcity}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="birthcity"
                  required
                  label="* Lugar de nacimiento"
                  invalid={errors.birthcity && touched.birthcity}
                  error={errors.birthcity}
                />
              </Cell>
              <Cell desktopColumns={12} tabletColumns={8}>
                <Typography
                  style={{ marginTop: 20 }}
                  className="mdc-color__secondary"
                  use="headline5"
                  tag="div"
                >
                  Datos de contacto
                </Typography>
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.email}
                  outlined
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  name="email"
                  label="* Correo electrónico"
                  invalid={errors.email && touched.email}
                  error={errors.email}
                />
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <PhoneInput
                  value={values.phoneNumber}
                  style={{ width: '100%' }}
                  country="mx"
                  autoFormat
                  onChange={(event) => setFieldValue('phoneNumber', event)}
                  name="phoneNumber"
                  placeholder="* Número de teléfono"
                />
                <ErrorMessage style={{ marginTop: 3 }}>
                  {errors.phoneNumber}
                </ErrorMessage>
              </Cell>
              <Cell desktopColumns={12} tabletColumns={8}>
                <Typography
                  style={{ marginTop: 20 }}
                  className="mdc-color__secondary"
                  use="headline6"
                  tag="div"
                >
                  Dirección
                </Typography>
              </Cell>
              <Cell desktopColumns={12} tabletColumns={8}>
                <TextInput
                  value={values.street}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="street"
                  label="* Calle"
                  error={errors.street}
                  invalid={errors.street && touched.street}
                />
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.location}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="location"
                  label="* Colonia o Localidad"
                  error={errors.location}
                  invalid={errors.location && touched.location}
                />
              </Cell>

              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.district}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="district"
                  label="* Municipio"
                  error={errors.district}
                  invalid={errors.district && touched.district}
                />
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.state}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="state"
                  label="* Estado"
                  error={errors.state}
                  invalid={errors.state && touched.state}
                />
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.zipCode}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="zipCode"
                  error={errors.zipCode}
                  invalid={errors.zipCode && touched.zipCode}
                  label="* Código postal"
                />
              </Cell>

              <Cell desktopColumns={12} tabletColumns={8}>
                <Typography
                  style={{ marginTop: 20 }}
                  className="mdc-color__secondary"
                  use="headline5"
                  tag="div"
                >
                  Población indígena a la que pertenece
                </Typography>
              </Cell>

              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.population}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="population"
                  label="Pueblo indígena al que pertenece"
                  error={errors.population}
                  invalid={errors.population && touched.population}
                />
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.language}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="language"
                  label="Lengua indígena que habla"
                  error={errors.language}
                  invalid={errors.language && touched.language}
                />
              </Cell>

              <Cell desktopColumns={12} tabletColumns={8}>
                <Select
                  outlined
                  name="languageLevel"
                  value={values.languageLevel}
                  label="Nivel de dominio de lengua indígena"
                  placeholder="-- Selecciona una opción --"
                  options={languageLevels}
                  onChange={(event) => {
                    setFieldValue('languageLevel', event.currentTarget.value);
                  }}
                />
                <ErrorMessage style={{ marginTop: 3 }}>
                  {errors.languageLevel}
                </ErrorMessage>
              </Cell>

              <Cell desktopColumns={12} tabletColumns={8}>
                <Typography
                  style={{ marginTop: 20 }}
                  className="mdc-color__secondary"
                  use="headline5"
                  tag="div"
                >
                  Datos académicos
                </Typography>
              </Cell>
              <Cell desktopColumns={12} tabletColumns={8}>
                <Typography
                  style={{ marginBottom: 10 }}
                  className="mdc-color__secondary"
                  use="headline6"
                  tag="div"
                >
                  Institución donde cursa sus estudios actualmente
                </Typography>
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.institutionName}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="institutionName"
                  label="* Nombre de la institución"
                  error={errors.institutionName}
                  invalid={errors.institutionName && touched.institutionName}
                />
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <PhoneInput
                  value={values.institutionPhoneNumber}
                  style={{ width: '100%' }}
                  country="mx"
                  autoFormat
                  onChange={(event) => {
                    setFieldValue('institutionPhoneNumber', event);
                  }}
                  name="institutionPhoneNumber"
                  placeholder="* Número de teléfono de la institución"
                />
                <ErrorMessage style={{ marginTop: 3 }}>
                  {errors.institutionPhoneNumber}
                </ErrorMessage>
              </Cell>
              <Cell desktopColumns={6} tabletColumns={4}>
                <TextInput
                  value={values.institutionCarrer}
                  outlined
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="institutionCarrer"
                  label="* Licenciatura o ingeniería que cursas actualmente"
                  error={errors.institutionCarrer}
                  invalid={
                    errors.institutionCarrer && touched.institutionCarrer
                  }
                />
              </Cell>

              <Cell desktopColumns={6} tabletColumns={4}>
                <Select
                  outlined
                  placeholder="-- Selecciona una opción --"
                  name="institutionGrade"
                  value={grades[values.institutionGrade - 1]}
                  label="* Semestre o cuatrimestre que cursas actualmente"
                  options={grades}
                  onChange={(event) => {
                    setFieldValue(
                      'institutionGrade',
                      grades.indexOf(event.currentTarget.value) + 1,
                    );
                  }}
                />
                <ErrorMessage style={{ marginTop: 3 }}>
                  {errors.institutionGrade}
                </ErrorMessage>
              </Cell>
              <Cell desktopColumns={12} tabletColumns={8}>
                <Typography
                  style={{ marginTop: 20 }}
                  className="mdc-color__secondary"
                  use="headline5"
                  tag="div"
                >
                  Documentos probatorios
                </Typography>
              </Cell>
              <Cell desktopColumns={4} tabletColumns={4}>
                <InputFile
                  name="Constancia de estudios"
                  label={
                    is.not.existy(values.constancia)
                      ? 'Constancia de estudios'
                      : values.constancia.name
                  }
                  onChange={(event) => {
                    setFieldValue('constancia', event.currentTarget.files[0]);
                  }}
                  icon="cloud_upload"
                />
              </Cell>
              <Cell desktopColumns={4} tabletColumns={4}>
                <InputFile
                  name="Carta de origen y vecindad"
                  label={
                    is.not.existy(values.cartaOrigen)
                      ? 'Carta de origen y vecindad'
                      : values.cartaOrigen.name
                  }
                  onChange={(event) => {
                    setFieldValue('cartaOrigen', event.currentTarget.files[0]);
                  }}
                  icon="cloud_upload"
                />
              </Cell>

              <Cell desktopColumns={4} tabletColumns={4}>
                <InputFile
                  name="INE (Identificación oficial)"
                  label={
                    is.not.existy(values.ineFile) ? 'INE' : values.ineFile.name
                  }
                  onChange={(event) => {
                    setFieldValue('ineFile', event.currentTarget.files[0]);
                  }}
                  icon="cloud_upload"
                />
              </Cell>

              <Cell desktopColumns={6} tabletColumns={4}>
                <InputFile
                  name="Carta de postulación"
                  label={
                    is.not.existy(values.cartaPost)
                      ? 'Carta de postulación'
                      : values.cartaPost.name
                  }
                  onChange={(event) => {
                    setFieldValue('cartaPost', event.currentTarget.files[0]);
                  }}
                  icon="cloud_upload"
                />
              </Cell>

              <Cell desktopColumns={6} tabletColumns={4}>
                <InputFile
                  name="Comprobante de seguro médico"
                  label={
                    is.not.existy(values.compMed)
                      ? 'Comprobante de seguro médico'
                      : values.compMed.name
                  }
                  onChange={(event) => {
                    setFieldValue('compMed', event.currentTarget.files[0]);
                  }}
                  icon="cloud_upload"
                />
              </Cell>
            </Row>
            <br />
            <br />
            <Cell desktopColumns={12} tabletColumns={8}>
              <Typography
                style={{ marginBottom: 10, color: 'red' }}
                use="headline6"
                tag="div"
              >
                **Favor de adjuntar cada documento en PDF.
              </Typography>
            </Cell>
            <Cell desktopColumns={6} tabletColumns={4}>
              <TextInput
                value={values.youtubeUrl}
                outlined
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="youtubeUrl"
                label="* Enlace de video de Youtube de presentacioń."
                error={errors.youtubeUrl}
                invalid={errors.youtubeUrl && touched.youtubeUrl}
              />
            </Cell>

            <br />
            <br />
            <Row>
              <Cell desktopColumns={12} tabletColumns={8}>
                <Button
                  style={{ width: '100%' }}
                  unelevated
                  type="submit"
                  disabled={is.not.empty(errors)}
                >
                  {!isSubmitting ? 'Enviar información' : 'Enviando...'}
                </Button>
              </Cell>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Seminario2020Form;
