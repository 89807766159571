import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Material
import { Fab } from '@rmwc/fab';
import { Typography } from '@rmwc/typography';

// Estilos
import '@rmwc/fab/styles';

const InputFile = ({ label, icon, onChange, name }) => {
  const inputRef = useRef(null);

  const handleClick = () => inputRef.current.click();

  return (
    <>
      <Typography
        style={{ marginBottom: 10, textAlign: 'center' }}
        use="headline6"
        tag="div"
      >
        {name}
      </Typography>
      <Fab
        style={{ width: '100%', color: 'white', backgroundColor: '#c81f66' }}
        icon={icon}
        type="button"
        label={label}
        onClick={handleClick}
        ppp
      />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={onChange}
        accept="application/pdf"
      />
    </>
  );
};

InputFile.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};
export default InputFile;
