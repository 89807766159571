import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@rmwc/typography';

const ErrorMessage = ({ children, style }) => {
  return (
    <Typography style={{ color: 'red', ...style }} tag="div" use="body2">
      {children}
    </Typography>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  // eslint-disable-next-line
  style: PropTypes.object,
};

export default ErrorMessage;
