import * as Yup from 'yup';

const Seminario2020Schema = Yup.object().shape({
  firstName: Yup.string()
    .required('El nombre es obligatorio.')
    .min(5, 'El nombre es demasiado corto.')
    .max(50, 'El nombre es demasiado largo, verifica si no hay algún error.'),
  lastName: Yup.string()
    .required('Los apellidos son obligatorios.')
    .min(5, 'Los apellidos es demasiado corto.')
    .max(
      80,
      'Los apellidos son demasiado largos, verifica si no hay algún error.',
    ),
  email: Yup.string()
    .required('El correo electrónico es obligatorio.')
    .email(
      'El valor que ingresaste es incorrecto, por favor, verifica y vuelve a escribir.',
    ),
  phoneNumber: Yup.string().required('El número de telefono es obligatorio'),
  genre: Yup.string()
    .required('El género es obligatorio')
    .matches(/(female|male)/, 'Solamente puedes elegir masculino o femenino.'),
  birthday: Yup.date().required('La fecha de nacimiento es obligatoria.'),
  birthcity: Yup.string()
    .required('El lugar de nacimiento es obligatorio.')
    .min(4, 'El lugar de nacimiento es demasiado corto.')
    .max(
      50,
      'El lugar de nacimiento es demasiado largo, verifica si no hay algún error.',
    ),
  street: Yup.string().required('La calle es obligatoria.'),
  location: Yup.string().required('La localidad o colonia es obligatoria.'),
  district: Yup.string().required('El municipio es obligatorio.'),
  state: Yup.string().required('El estado es obligatorio.'),
  zipCode: Yup.string()
    .required('El código postal es obligatorio.')
    .length(
      5,
      'El código postal debe contener 5 dígitos, verifica si no hay algún error.',
    ),
  population: Yup.string()
    .required('La población a la que pertenece es obligatoria.')
    .min(
      3,
      'Debe de contener al menos 3 dígitos, verifica si no hay algún error.',
    ),
  language: Yup.string()
    .required(
      'El lenguaje es obligatorio, en caso de no hablar ninguno, pon "Ninguno".',
    )
    .min(
      3,
      'Debe de contener al menos 3 dígitos, verifica si no hay algún error.',
    ),
  languageLevel: Yup.string().required(
    'El nivel de dominio de la lengua es obligatorio. En caso de no tener dominio de lengua, escoja la opción: Ninguno.',
  ),
  institutionName: Yup.string().required(
    'El nombre de la institución a la que pertenece es obligatorio.',
  ),
  institutionPhoneNumber: Yup.string().required(
    'El número de teléfono es obligatorio.',
  ),
  institutionCarrer: Yup.string().required(
    'La carrera que cursa actualmente es obligatoria.',
  ),
  institutionGrade: Yup.string().required(
    'El cuatrimestre o semestre que cursa actualmente es obligatorio.',
  ),
  // constancia: Yup.object({
  //   name: Yup.string(),
  //   type: Yup.string(),
  // }).required('Es necesario subir este archivo'),
  // cartaOrigen: Yup.object({
  //   name: Yup.string().required('Es necesario subir este archivo'),
  // }),
  // ineFile: Yup.object({
  //   name: Yup.string().required('Es necesario subir este archivo'),
  // }),
  // cartaPost: Yup.object({
  //   name: Yup.string().required('Es necesario subir este archivo'),
  // }),
  // compMed: Yup.object({
  //   name: Yup.string().required('Es necesario subir este archivo'),
  // }),
  youtubeUrl: Yup.string()
    .required('El enlace del video de presentación es obligatio.')
    .url(
      'El enlace no está en un formato permitido, verifica si no hay algún error.',
    ),
});

export default Seminario2020Schema;
