import React, { useEffect } from 'react';
import axios from 'axios';

// Material Design
import { Grid, Row, Cell } from '@material/react-layout-grid';
import { Headline2, Headline4, Headline5 } from '@material/react-typography';

// Layout y componentes
import Layout from '../../components/layout';
import Newsletter from '../../components/shared/newsletter';
import Seminario2020Form from '../../components/forms/seminario-2020-form';
import SEO from '../../components/seo';

const RegistroPage = () => {
  useEffect(() => {
    const pingToServer = async () => {
      await axios({
        method: 'GET',
        url: 'https://rocky-brushlands-23814.herokuapp.com/api/v1/ping',
        headers: {
          'x-api-key': process.env.GATSBY_CMUJER_API_KEY,
        },
      });
    };

    pingToServer();
  }, []);

  return (
    <Layout>
      <SEO
        description="Registrate para poder participar en el Seminario Nacional de Mujeres Indígeneas Universitarias en Ciencia y Tecnología para el Desarrollo Sostenible"
        title="Registro Seminario 2020"
      />
      <Grid className="mdc-section--seminario">
        <Row>
          <Cell desktopColumns={3} tabletColumns={8} phoneColumns={4} />
          <Cell desktopColumns={6} tabletColumns={8} phoneColumns={4}>
            <Headline2 style={{ textAlign: 'center' }}>
              Seminario Nacional de Mujeres Indígenas Universitarias en Ciencia
              y Tecnología para el Desarrollo Sostenible
            </Headline2>

            <Headline5 style={{ textAlign: 'center', color: '#3d2562' }}>
              27, 28 y 29 de Octubre de 2020
            </Headline5>
            <Headline4 style={{ textAlign: 'center', color: '#3d2562' }}>
              Registro
            </Headline4>
          </Cell>
        </Row>
        <br />
        <br />
        <Row>
          <Cell desktopColumns={2} tabletColumns={8} phoneColumns={4} />
          <Cell desktopColumns={8} tabletColumns={8} phoneColumns={4}>
            <Seminario2020Form />
          </Cell>
        </Row>
        <Row>
          <Cell desktopColumns={3} phoneColumns={4} tabletColumns={8} />
          <Cell desktopColumns={12} phoneColumns={4} tabletColumns={8}>
            <Newsletter />
          </Cell>
        </Row>
      </Grid>
    </Layout>
  );
};

export default RegistroPage;
