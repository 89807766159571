import styled from 'styled-components';

import { Radio as Rd } from '@rmwc/radio';

const Radio = styled(Rd)`
  color: ${({ color = 'black' }) => color};
  margin-right: 10px;

  & .mdc-radio__native-control {
    color: ${({ color = 'black' }) => color};
    background-color: ${({ color = 'black' }) => color};
    border-color: ${({ color = 'black' }) => color};
  }

  &:last-child {
    margin-right: 10px;
  }
`;

export default Radio;
